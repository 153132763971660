import { useMutation } from "@vue/apollo-composable";
import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from "@apollo/client/core";

import { session } from '@/utils/users';

const additiveLink = from([
    new ApolloLink((operation, forward) => {
        const currentSession = session.get('user');

        if (currentSession?.token) {
            operation.setContext(() => ({
                headers: {
                    authorization: currentSession?.token ? "Bearer " + currentSession.token : null,
                },
            }));
        }

        return forward(operation);
    }),

    new HttpLink({
        uri: function () {
            return import.meta.env.VITE_api_cram_base_url;
        },
    }),
]);


export const apolloClient = new ApolloClient({
    link: additiveLink,
    cache: new InMemoryCache(),
    assumeImmutableResults: true,
});
