
export const session = {
    get(key) {
        return JSON.parse(localStorage.getItem(key));
    },

    set(key, payload) {
        localStorage.setItem(key, JSON.stringify(payload));
        
        return new Promise((resolve, reject) => {
            resolve();
        });
    }
}