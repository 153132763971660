<script setup>
    import { onBeforeMount } from "vue";
    import ArrowDown from "@/components/icons/IconArrowDown.vue";
    import IconHerringBoneRight from "@/components/icons/IconHerringBoneRight.vue";
    import IconCart from "@/components/icons/IconCart.vue";
    import IconSearch from "@/components/icons/IconSearch.vue";
    import TheMegaMenu from "@/components/core/TheMegaMenu.vue";

    import { useCore } from '@/stores/core';
    const store = useCore();
    const coreSettings = store.fetchCoreSettings();

    import { useCart } from '@/stores/cart';
    const storeCart = useCart();

    onBeforeMount(async () => {
        let cart = JSON.parse(localStorage.getItem('cart'));

        if (cart !== null) {
            storeCart.data.items_count = cart.length;
        }        
    });
</script>

<template>
    <section class="container relative text-sm">
        <div @click="store.toggleMegaMenu('all', false)">
            <!-- Region selector -->
            <div @click.prevent="store.toggleRegionDialog()" class="absolute top-5 left-[20px] md:left-[50px] lg:left-[70px]">
                <div>
                    <a href="#" class="group inline-block mr-2 text-blue hover:cursor-pointer">
                        <span v-if="store.region === 'north_america'">Amérique du Nord</span>
                        <span v-else>Europe</span>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                    </a>
                    <ArrowDown class="inline -mt-0.5 w-[10px] h-[10px] text-blue" />
                </div>
            </div>

            <!-- Secondary nav -->
            <nav class="absolute flex top-5 right-[20px] md:right-[50px] lg:right-[70px]">
                <a href="/nous-joindre" class="mr-9 group text-blue hidden md:block">
                    Nous joindre
                    <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                </a>

                <a target="_blank" href="https://portail.cramformation.com/" class="mr-9 group text-blue hidden md:block">
                    Portail étudiant
                    <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                </a>

                <span>
                    <div class="inline-block mr-1.5 text-blue">
                        <IconCart class="inline ml-1 w-[20px] h-[20px]" />
                    </div>
                    <div class="inline-block">
                        <a href="/panier" class="group text-blue">
                            Panier ({{ storeCart.data.items_count }})
                            <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                        </a>
                    </div>
                </span>
            </nav>

            <!-- Logo -->
            <div class="pt-7 lg:pt-0">
                <div class="flex justify-center items-center">
                    <a href="/"><img class="w-[200px] md:w-[250px] lg:w-[295px]" src="/logotype-cram-rgb.svg" alt="CRAM Formation"></a>
                </div>
            </div>

            <!-- Burger -->
            <div @click.prevent="store.toggleBurgerMenu()" class="absolute top-[115px] left-[20px] md:hidden">
                <img v-if="!store.show_burger" width="30" src="/burger.png">
                <img v-if="store.show_burger" width="30" src="/btn-burger-close.png">
            </div>
        </div>

        <!-- Main nav -->
        <nav class="hidden md:flex bg-blue uppercase text-lg pb-2 lg:p-2 pl-0  justify-center items-center">
            <div class="bg-blue h-[35px] w-[20px]" @click="store.toggleMegaMenu('all', false)"></div> 

            <span class="mr-6 lg:mr-12 pt-1 cursor-pointer" @click="store.toggleMegaMenu('about', true)">
                <div class="inline-block">
                    <div class="group text-white text-xs lg:text-lg">
                        À propos du cram-eif
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-white"></span>
                    </div>
                </div>
                <div class="hidden xl:inline-block mr-1.5 text-white">
                    <ArrowDown class="ml-1 -mt-1 w-[10px] h-[10px]" />
                </div>
            </span>

            <span class="mr-6 lg:mr-12 pt-1 cursor-pointer" @click="store.toggleMegaMenu('andc', true)">
                <div class="inline-block">
                    <div class="group text-white text-xs lg:text-lg">
                        L’approche ANDC<sup>®</sup>
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-white"></span>
                    </div>
                </div>
                <div class="hidden xl:inline-block  mr-1.5 text-white">
                    <ArrowDown class="ml-1 -mt-1 w-[10px] h-[10px]" />
                </div>
            </span>

            <span class="mr-6 lg:mr-12 pt-1 cursor-pointer" @click="store.toggleMegaMenu('become_tra', true)">
                <div class="inline-block">
                    <div class="group text-white text-xs lg:text-lg">
                        Devenir thérapeute
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-white"></span>
                    </div>
                </div>
                <div class="hidden xl:inline-block mr-1.5 text-white">
                    <ArrowDown class="ml-1 -mt-1 w-[10px] h-[10px]" />
                </div>
            </span>

            <span class="mr-6 lg:mr-12 pt-1 cursor-pointer" @click="store.toggleMegaMenu('personal_development', true)">
                <div class="inline-block">
                    <div class="group text-white text-xs lg:text-lg">
                        Grand public
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-white"></span>
                    </div>
                </div>
                <div class="hidden xl:inline-block mr-1.5 text-white">
                    <ArrowDown class="ml-1 -mt-1 w-[10px] h-[10px]" />
                </div>
            </span>

            <span class="mr-6 lg:mr-12 pt-1 cursor-pointer" @click="store.toggleMegaMenu('graduates', true)">
                <div class="inline-block">
                    <div class="group text-white text-xs lg:text-lg">
                        Diplômés
                        <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-white"></span>
                    </div>
                </div>
                <div class="hidden xl:inline-block mr-1.5 text-white">
                    <ArrowDown class="ml-1 -mt-1 w-[10px] h-[10px]" />
                </div>
            </span>

            <div class="md:mr-2 xl:mr-0 ml-4 pt-2 lg:pt-1">
                <a href="/recherche"><IconSearch class="text-white inline ml-1 -mt-1 w-[18px] h-[18px] transform transition duration-500 hover:scale-150" /></a>
            </div> 
        </nav>

        <!-- Mega menu -->
        <TheMegaMenu class="hidden md:block" />
    </section>
</template>


