<script setup>
    import FormInput from "@/components/forms/widgets/FormInput.vue";
    import FormInputError from "@/components/forms/widgets/FormInputError.vue";
    import FormSelect from "@/components/forms/widgets/FormSelect.vue";
    
    import { useRegistrations } from '@/stores/registrations';
    const store = useRegistrations();
</script>

<template>
    <section class="bg-blue container">
        <div class="p-8 lg:p-16 text-white border-b border-white">
            <p>Le Centre de relation d’aide de Montréal (CRAM<sup>®</sup>) et l’École internationale de formation à l’ANDC (EIF) sont des écoles spécialisées en formation professionnelle, en développement personnel et en relation d’aide par l’Approche non directive créatrice<sup>®</sup> (ANDC<sup>®</sup>) créée par Colette Portelance. Le CRAM<sup>®</sup> est basé à Montréal (Québec/Canada) et l’EIF en France/Europe. Ces deux établissements de formation à l’ANDC<sup>®</sup> comptent plus de 500 étudiants par année à travers toute la francophonie. La formation professionnelle en relation d’aide par l’ANDC<sup>®</sup> permet à chaque étudiant d’approfondir la connaissance de soi et la relation à l’autre, et lui permettra d’être un thérapeute reconnu comme véritable spécialiste des relations humaines, et ainsi de devenir membre de la CITRAC ou de l’AETRA pour porter le titre de TRA, Thérapeute en relation d’aide<sup>®</sup>. Par la suite, le TRA<sup>®</sup> pourra se spécialiser grâce aux formations dans les domaines suivants : thérapie de couples, accompagnement du deuil, thérapie pour enfants/adolescents, coaching en relations professionnelles, coaching parental, etc. Ce cursus diplômant favorise également le développement de l’amour de soi, la confiance en soi, l’épanouissement, la créativité, le cheminement vers l’autonomie et la liberté d’être, tout cela dans le respect du fonctionnement global de l’être humain et du rythme de progression des étapes de son évolution, de sa croissance et de son autocréation.</p>
        </div>

        <div class="md:flex gap-2 p-8 lg:p-12 xl:p-16 pb-4 text-white">
            <nav class="text-base lg:text-xl md:w-1/3">
                <p><a class="hover:underline" href="/a-propos-du-cram-eif">À propos du CRAM•EIF</a></p>
                <p class="mt-2"><a class="hover:underline" href="/l-approche-andc">L’approche ANDC</a></p>
                <p class="mt-2"><a class="hover:underline" href="/devenir-therapeute">Devenir thérapeute</a></p>
                <p class="mt-2"><a class="hover:underline" href="/grand-public">Grand public</a></p>
                <p class="mt-2"><a class="hover:underline" href="/diplomes-du-cram">Diplômés</a></p>
                <p class="mt-2"><a class="hover:underline" href="/a-propos-du-cram-eif/actualites">Actualités</a></p>
            </nav>

            <nav class="text-base lg:text-xl md:w-1/3">
                <p><a class="hover:underline" href="/nous-joindre">Nous joindre</a></p>
                <p class="mt-2"><a class="hover:underline" href="/infolettre">Infolettre</a></p>
                <p class="mt-2"><a class="hover:underline" href="https://portail.cramformation.com/" target="_blank">Portail étudiant</a></p>
                <p class="mt-2"><a class="hover:underline" href="/annulations-et-remboursements">Annulation et remboursement</a></p>
                <p class="mt-2"><a class="hover:underline" href="/politique-de-confidentialite">Politique de confidentialité</a></p>
                <p class="mt-2"><a class="hover:underline" href="/politique-dinclusion">Politique d’inclusion</a></p>
            </nav>

            <div class="mt-8 md:mt-0 text-base lg:text-xl md:w-1/3">
                <a id="infolettre"></a>
                <p class="text-base lg:text-2xl font-bold">Inscrivez-vous à l’infolettre</p>
                <p class="text-base lg:text-xl mt-2">Pour rester informé sur notre actualité et rester à l’affut des formations et activités spéciales.</p>
                
                <div v-if="!store.data.is_subscribed" class="flex-col mt-4">
                    <div class="flex flex-col">
                        <label class="text-white font-semibold text-xl pb-1">Courriel</label>
                        <FormInput  v-model="store.data.email" />
                        <FormInputError :error=store.errors.email />
                    </div>

                    <div class="flex flex-col mt-4">
                        <label class="text-white font-semibold text-xl pb-1">Région</label>
                        <FormSelect v-model="store.data.region" :options=store.regionOptions />
                    </div>

                    <button @click.prevent="store.submitNewsletterForm()"  class="mt-8 uppercase text-lg font-semibold border-2 border-white text-white rounded py-2 px-6 text-center bg-transparent hover:bg-white hover:text-blue transition-all duration-300">S'inscrire</button>
                </div>

                <div v-if="store.data.is_subscribed" class="mt-4 font-bold">
                    <p>Vous recevrez un courriel de confirmation sous peu afin de compléter votre inscription. Merci!</p>
                </div>
            </div>
        </div>

        <div class="p-16 pt-4 text-white">
            <p class="mb-2">Visitez-nous sur:</p>
            <div class="flex gap-5">
                <a href="https://www.facebook.com/CRAM.EIF"><img src="/facebook.svg" target="_blank"></a>
                <a href="https://www.youtube.com/user/CRAM5987758"><img src="/youtube.svg" target="_blank"></a>
                <a href="https://www.instagram.com/crameif/" target="_blank"><img src="/instagram.svg"></a>
            </div>
        </div>

        <div class="flex px-8 lg:px-16 py-8 text-white border-t border-white">
            <figure class="mr-4"><img src="/logo-cram-blanc.svg"></figure>
            <p class="basis-9/12 text-sm">© {{ new Date().getFullYear() }} Centre de relation d’aide de Montréal inc. CRAM, ANDC, APPROCHE NON DIRECTIVE CRÉATRICE & le Service ANDC-AIDE sont des marques de commerce (MD). Tous droits réservés.</p>
        </div>
    </section>
</template>
