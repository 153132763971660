import { createApp, h, provide, markRaw } from 'vue'
import { createPinia } from "pinia";
import { DefaultApolloClient, provideApolloClient } from "@vue/apollo-composable";
import Datepicker from '@vuepic/vue-datepicker';
import * as Dialog from 'vuejs-confirm-dialog'
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import './index.css';
import router from "./routes/core";
import { apolloClient } from "@/utils/apollo";

const app = createApp({
    setup() {
        provideApolloClient(apolloClient);
        provide(DefaultApolloClient, apolloClient);
    },
    render: () => h(App),
});

if (import.meta.env.VITE_app_env !== 'local') {
    Sentry.init({
        app,
        dsn: "https://ce2d6f95d45d6a649f5a33636df40720@o4508858158809088.ingest.us.sentry.io/4508858160381952",
        integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0, 
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router)
});

app.use(router)
    .use(pinia)
    .use(Dialog)
    .component('date-picker', Datepicker);

app.mount("#app");
