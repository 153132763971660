<script setup>
    import { onMounted, ref } from 'vue'
    import FormButton from "@/components/forms/widgets/FormButton.vue";
    import IconSearch from "@/components/icons/IconSearch.vue";

    import TheHeader from "@/components/core/TheHeader.vue";
    import TheFooter from "@/components/core/TheFooter.vue";

    import { useCore } from '@/stores/core';
    const store = useCore();

    const isVisible = ref(false);

    onMounted(() => {
        if (localStorage.getItem('region')) {
            store.region = localStorage.getItem('region');
        }

        if (localStorage.getItem('cookies')) {
            store.cookies = localStorage.getItem('cookies');
            store.show_cookies_dialog = false;
        }

        const isInEu = Intl.DateTimeFormat().resolvedOptions().timeZone.indexOf("Europe") == 0;
        if (isInEu) {
            store.region = 'europe';
        }

        setTimeout(function() {
            isVisible.value = true;
        }, 1500, this);
    })
</script>

<template>
    <TheHeader />

    <main>
        <router-view :key="$route.path" />
    </main>

    <div v-if="store.show_region_dialog" class="absolute top-0 left-0 z-50 bg-bg_sand w-[100%] h-[100%]">
        <div class="absolute w-[100%] h-screen">

            <div @click.prevent="store.toggleRegionDialog()" class="w-[44px] absolute top-6 right-6 hover:cursor-pointer">
                <img src="/btn-close.png" />
            </div>

            <div class="absolute w-[75%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -mt-12">
                <p class="text-2xl md:text-3xl lg:text-7xl text-center">Choisir votre région</p>

                <div class="mt-8 block md:flex justify-center h-[50px]">
                    <div 
                        @click.prevent="store.setRegion('north_america')" 
                        :class="{ 
                            'border-blue': store.region === 'north_america', 
                            'bg-blue': store.region === 'north_america', 
                            'text-white': store.region === 'north_america',
                            'hover:bg-white': store.region !== 'north_america', 
                            'hover:text-blue': store.region !== 'north_america', 
                            'bg-white': store.region !== 'north_america', 
                            'text-blue': store.region !== 'north_america', 
                            'hover:cursor-pointer': store.region !== 'north_america' 
                        }" 
                        
                        class="w-full md:w-auto flex justify-center mr-8 select-none py-2 px-4 xl:py-2 xl:px-6 uppercase text-lg font-semibold border-2 rounded transition-all duration-300">

                        <span v-if="store.region === 'north_america'" class="w-[28px] mr-2"><img src="/icon-check.png"></span>
                        <span>Amérique du Nord</span>
                    </div>

                    <div 
                        @click.prevent="store.setRegion('europe')" 
                        :class="{ 
                            'border-blue': store.region === 'europe', 
                            'bg-blue': store.region === 'europe', 
                            'text-white': store.region === 'europe',
                            'hover:bg-white': store.region !== 'europe', 
                            'hover:text-blue': store.region !== 'europe', 
                            'bg-white': store.region !== 'europe', 
                            'text-blue': store.region !== 'europe', 
                            'hover:cursor-pointer': store.region !== 'europe' 
                        }" 
                        
                        class="flex justify-center mt-6 md:mt-0 w-full md:w-auto mr-8 select-none py-2 px-4 xl:py-2 xl:px-6 uppercase text-lg font-semibold border-2 rounded transition-all duration-300">
  
                        <span v-if="store.region === 'europe'" class="w-[28px] mr-2"><img src="/icon-check.png"></span>
                        <span>Europe</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="store.show_cookies_dialog" class="fixed bottom-0 left-0 z-50 bg-blue w-[100%] h-[390px] md:h-[225px] lg:h-[175px]">
        <div class="container flex flex-col md:flex-row p-6">

            <div class="text-white mr-0 md:mr-8">
                <h3 class="font-semibold text-base md:text-xl">Ce site web utilise des fichiers témoins («cookies»)</h3>
                <p class="mt-2 text-sm">Ces fichiers nous permettent de vous offrir des fonctionnalités telles que la sélection de région, la rétention de vos choix d’inscription ou votre utilisation du panier. Nous pouvons ainsi améliorer votre expérience de navigation et analyser le trafic sur le site web. En cliquant sur «accepter», vous consentez à cette utilisation. Cliquez ici pour consulter notre <a class="underline underline-offset-2 text-white" href="/politique-de-confidentialite">politique de confidentialité</a>.</p>
            </div>

            <div class="flex mt-6 md:mt-4 flex-col w-[200px] h-[50px]">
                    <div 
                        @click.prevent="store.setCookies(true)" 
                        class="mb-4 border-cram_pale_blue hover:bg-white hover:cursor-pointer hover:text-cram_pale_blue bg-cram_pale_blue text-white select-none md:py-2 md:px-4 xl:py-2 xl:px-6 uppercase text-lg font-semibold border-2 rounded text-center transition-all duration-300">
                        <span>Accepter</span>
                    </div>

                    <div 
                        @click.prevent="store.setCookies(false)" 
                        class="border-cram_pale_blue bg-white hover:cursor-pointer text-cram_pale_blue hover:text-white hover:bg-cram_pale_blue select-none md:py-2 md:px-4 xl:py-2 xl:px-6 uppercase text-lg font-semibold border-2 rounded text-center transition-all duration-300">
                        <span>Refuser</span>
                    </div>
                </div>
        </div>
    </div>

    <div v-if="store.show_burger" class="md:hidden absolute top-[170px] left-0 z-50 bg-cram_blue w-[100%] h-[100%] text-white">

        <div class="px-8 pt-8">
            <p @click.prevent="store.showSubMenu('about')" class="overflow-hidden uppercase pb-1">
                <span class="float-left">{{ store.navigation.main[1].name }}</span>
                <img v-if="store.navigation.mobile_show_about" class="w-6 float-right -rotate-90" src="/icon-arrow-left.png">
                <img v-if="!store.navigation.mobile_show_about" class="w-6 float-right" src="/icon-arrow-right.png">
            </p>

            <ul v-if="store.navigation.mobile_show_about" class="pb-8">
                <li v-for="page in store.navigation.main[1].children" :key="page.slug" >
                    <p>
                        <a :href="page.slug" class="underline">{{ page.name }}</a>
                    </p>
                </li>
            </ul>
        </div>

        <div class="px-8">
            <p @click.prevent="store.showSubMenu('andc')" class="overflow-hidden uppercase pb-1">
                <span class="float-left">{{ store.navigation.main[2].name }}</span>
                <img v-if="store.navigation.mobile_show_andc" class="w-6 float-right -rotate-90" src="/icon-arrow-left.png">
                <img v-if="!store.navigation.mobile_show_andc" class="w-6 float-right" src="/icon-arrow-right.png">
            </p>

            <ul v-if="store.navigation.mobile_show_andc" class="pb-8">
                <li v-for="page in store.navigation.main[2].children" :key="page.slug" >
                    <p>
                        <a :href="page.slug" class="underline">{{ page.name }}</a>
                    </p>
                </li>
            </ul>
        </div>

        <div class="px-8">
            <p @click.prevent="store.showSubMenu('training')" class="overflow-hidden uppercase pb-1">
                <span class="float-left">{{ store.navigation.main[3].name }}</span>
                <img v-if="store.navigation.mobile_show_training" class="w-6 float-right -rotate-90" src="/icon-arrow-left.png">
                <img v-if="!store.navigation.mobile_show_training" class="w-6 float-right" src="/icon-arrow-right.png">
            </p>

            <ul v-if="store.navigation.mobile_show_training" class="pb-8">
                <li v-for="page in store.navigation.main[3].children" :key="page.slug" >
                    <p>
                        <a :href="page.slug" class="underline">{{ page.name }}</a>
                    </p>
                </li>
            </ul>
        </div>

        <div class="px-8">
            <p @click.prevent="store.showSubMenu('mainstream')" class="overflow-hidden uppercase pb-1">
                <span class="float-left">{{ store.navigation.main[4].name }}</span>
                <img v-if="store.navigation.mobile_show_mainstream" class="w-6 float-right -rotate-90" src="/icon-arrow-left.png">
                <img v-if="!store.navigation.mobile_show_mainstream" class="w-6 float-right" src="/icon-arrow-right.png">
            </p>

            <ul v-if="store.navigation.mobile_show_mainstream" class="pb-8">
                <li v-for="page in store.navigation.main[4].children" :key="page.slug" >
                    <p>
                        <a :href="page.slug" class="underline">{{ page.name }}</a>
                    </p>
                </li>
            </ul>
        </div>

        <div class="px-8 pb-8">
            <p @click.prevent="store.showSubMenu('graduates')" class="overflow-hidden uppercase pb-1">
                <span class="float-left">{{ store.navigation.main[5].name }}</span>
                <img v-if="store.navigation.mobile_show_graduates" class="w-6 float-right -rotate-90" src="/icon-arrow-left.png">
                <img v-if="!store.navigation.mobile_show_graduates" class="w-6 float-right" src="/icon-arrow-right.png">
            </p>

            <ul v-if="store.navigation.mobile_show_graduates" class="pb-8">
                <li v-for="page in store.navigation.main[5].children" :key="page.slug" >
                    <p>
                        <a :href="page.slug" class="underline">{{ page.name }}</a>
                    </p>
                </li>
            </ul>
        </div>

        <ul class="px-8">
            <li v-for="page in store.navigation.secondary" :key="page.slug">
                <p>
                    <a :href="page.slug" class="underline">{{ page.name }}</a>
                </p>
            </li>
        </ul>

        <div class="mt-8 mx-8">
            <a href="/recherche"><IconSearch class="text-white inline mr-1 -mt-1 w-[18px] h-[18px] transform transition duration-500 hover:scale-150" /> Recherche</a>
        </div> 
    </div>

    <DialogsWrapper />
    
    <TheFooter v-if="isVisible" />
</template>