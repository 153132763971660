<script setup>
    import { computed } from "vue";
    import ArrowDown from "@/components/icons/IconArrowDown.vue";
    import IconHerringBoneRight from "@/components/icons/IconHerringBoneRight.vue";
    import IconCart from "@/components/icons/IconCart.vue";
    import IconSearch from "@/components/icons/IconSearch.vue";

    import { useCore } from '@/stores/core';
    const store = useCore();

    const navigation = store.fetchNavigation();
    const nav_about = computed(() => store.navigation.main.filter(parent => parent.slug == '/a-propos-du-cram-eif').shift());
    const nav_andc = computed(() => store.navigation.main.filter(parent => parent.slug == '/l-approche-andc').shift());
    const nav_become_tra = computed(() => store.navigation.main.filter(parent => parent.slug == '/devenir-therapeute').shift());
    const nav_personal_development = computed(() => store.navigation.main.filter(parent => parent.slug == '/grand-public').shift());
    const nav_graduates= computed(() => store.navigation.main.filter(parent => parent.slug == '/diplomes-du-cram').shift());
</script>

<template>
    <section>

        <!-- About -->
        <section>
            <div @mouseleave="store.toggleMegaMenu('about', false)" v-if="store.getter_show_about_menu && nav_about" class="absolute z-50 flex w-full md:h-[300px] lg:h-[440px] xl:h-[560px] md:pt-4 lg:pt-8 xl:p-16 bg-slate-100">
                <div class="w-2/3 pl-10">
                    <h2 class="text-xl lg:text-2xl lg:mr-20 xl:mr-64">Le Centre de Relation d’aide de Montréal et l’École internationale de formation à l’ANDC</h2>

                    <ul class="mt-4 lg:mt-8">
                        <li class="mt-1 lg:mt-4 text-base lg:text-lg" v-for="child in nav_about.children" :key="child.slug">
                            <IconHerringBoneRight class="inline -mt-0.5 w-[10px] h-[10px] text-blue mr-2" />
                            <a :href="child.slug" class="group inline-block text-blue font-semibold">
                                {{ child.name }}
                                <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-1/3">
                </div>
            </div>
        </section>

        <!-- ANDC -->
        <section>
            <div @mouseleave="store.toggleMegaMenu('andc', false)" v-if="store.getter_show_andc_menu && nav_andc" class="absolute z-50 flex w-full h-[560px] md:pt-4 lg:pt-8 xl:p-16 bg-slate-100">
                <div class="w-2/3 pl-10">
                    <h2 class="text-2xl mr-64">Approche non directive créatrice<sup>®</sup></h2>

                    <ul class="mt-8">
                        <li class="mt-4 text-lg" v-for="child in nav_andc.children" :key="child.slug">
                            <IconHerringBoneRight class="inline -mt-0.5 w-[10px] h-[10px] text-blue mr-2" />
                            <a :href="child.slug" class="group inline-block text-blue font-semibold">
                                {{ child.name }}
                                <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-1/3">
                    <!-- Prochaine séance d’information -->
                </div>
            </div>
        </section>

        <!-- Become TRA -->
        <section>
            <div @mouseleave="store.toggleMegaMenu('become_tra', false)" v-if="store.getter_show_become_tra_menu && nav_become_tra" class="absolute z-50 flex w-full h-[560px] md:pt-4 lg:pt-8 xl:p-16 bg-slate-100">
                <div class="w-2/3 pl-10">
                    <h2 class="text-2xl mr-64">Devenir thérapeute par l’ANDC<sup>®</sup></h2>

                    <ul class="mt-8">
                        <li class="mt-4 text-lg" v-for="child in nav_become_tra.children" :key="child.slug">
                            <IconHerringBoneRight class="inline -mt-0.5 w-[10px] h-[10px] text-blue mr-2" />
                            <a :href="child.slug" class="group inline-block text-blue font-semibold">
                                {{ child.name }}
                                <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-1/3">
                    <!-- Actualités -->
                </div>
            </div>
        </section>

        <!-- Personal Development -->
        <section>
            <div @mouseleave="store.toggleMegaMenu('personal_development', false)" v-if="store.getter_show_personal_development_menu && nav_personal_development" class="absolute z-50 flex w-full h-[560px] md:pt-4 lg:pt-8 xl:p-16 bg-slate-100">
                <div class="w-2/3 pl-10">
                    <h2 class="text-2xl mr-64">Développement personnel et activités pour le grand public</h2>

                    <ul class="mt-8">
                        <li class="mt-4 text-lg" v-for="child in nav_personal_development.children" :key="child.slug">
                            <IconHerringBoneRight class="inline -mt-0.5 w-[10px] h-[10px] text-blue mr-2" />
                            <a :href="child.slug" class="group inline-block text-blue font-semibold">
                                {{ child.name }}
                                <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-1/3">
                    <!-- Actualités -->
                </div>
            </div>
        </section>

        <!-- Graduates -->
        <section>
            <div @mouseleave="store.toggleMegaMenu('graduates', false)" v-if="store.getter_show_graduates_menu && nav_graduates" class="absolute z-50 flex w-full h-[560px] md:pt-4 lg:pt-8 xl:p-16 bg-slate-100">
                <div class="w-2/3 pl-10">
                    <h2 class="text-2xl mr-64">Diplômés du CRAM et de l’EIF</h2>

                    <ul class="mt-8">
                        <li class="mt-4 text-lg" v-for="child in nav_graduates.children" :key="child.slug">
                            <IconHerringBoneRight class="inline -mt-0.5 w-[10px] h-[10px] text-blue mr-2" />
                            <a :href="child.slug" class="group inline-block text-blue font-semibold">
                                {{ child.name }}
                                <span class="block max-w-0 group-hover:max-w-full transition-all duration-300 h-0.5 bg-blue"></span>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="w-1/3">
                    <!-- Actualités -->
                </div>
            </div>
        </section>

    </section>
</template>


